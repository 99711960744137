/**
 * Media breakpoints
 */
const breakpoints = {
    xs: 421,
    s: 767,
    m: 900,
    l: 1199,
    xl: 1440
};

/**
 * media queries
 */
const mediaBreakpoints = {
    tabletMax: `(max-width:${breakpoints.l}px)`, //(max-width: 1199px)
    mobileMax: `(max-width: ${breakpoints.s}px)`, //(max-width: 767px)
    desktopOnly: `(min-width: ${breakpoints.l + 1}px)`//(min-width: 1200px)
};

/**
 * example: processInclude(require('./components/search'));
 * @param {Object} include - required file
 */
function processInclude(include) {
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach((key) => {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
}

/**
 * example: getScreenSize('(min-width: 1025px)');
 * isMobile: getScreenSize(mediaBreakpoints.mobileMax),
 * @param {string} mediaQuery - media query string - (min-width: 1025px)
 * @returns {boolean} - returns boolean value if screen size matched or not
 */
function getScreenSize(mediaQuery) {
    return window.matchMedia(mediaQuery).matches;
}

/**
 * Checks if element is vertically and horizontally in viewport
 * @param {jQuery} el - jQuery object
 * @param {number} offsetToTop - tolerated margin, usually 0
 * @returns {boolean} - returns true if element is in view or false if not
 */
 function elementInViewport(el, offsetToTop) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    if (typeof offsetToTop !== 'undefined') {
        top -= offsetToTop;
    }

    if (window.pageXOffset !== null) {
        return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
    }

    if (document.compatMode === 'CSS1Compat') {
        return (
            top < window.document.documentElement.scrollTop + window.document.documentElement.clientHeight
            && left < window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth
            && top + height > window.document.documentElement.scrollTop
            && left + width > window.document.documentElement.scrollLeft
        );
    }
}

function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

function parseQueryString(filterUrl) {
    // get all url parameters as key value pairs
    var queryString = filterUrl.split('?')[1];
    var parameters = queryString.split('&');
    var prefs = {};
    for (var i = 0; i < parameters.length; i++) {
        var keyValuePair = parameters[i].split('=');

        if(keyValuePair.length == 2) {
            prefs[keyValuePair[0]] = keyValuePair[1];
        }
    }

    var result = {
        preferences: {}
    };

    var keys = Object.keys(prefs);
    for (var i = 0; i < keys.length; i++) {
        // add all parameters that do not start with pref
        if (prefs[keys[i]] && keys[i].indexOf('pref') != 0) {
            result[keys[i]] = prefs[keys[i]];
        }
    }

    // for each numbered pref parameter, add the key value pair to the result
    var maxPrefs = Math.floor(keys.length / 2);
    for (var i = 1; i <= maxPrefs; i++) {
        var key = prefs['prefn' + i];
        var value = prefs['prefv' + i];
        if(key == null || value == null) {
            continue;
        }

        result.preferences[key] = value;
    }

    return result;
}

/**
 * Scrolls the window to the top
 * Example: scrollToPosition(0, 1000);
 * @param {number} scrollPos - position which needs to be scrolled to
 * @param {number} duration  - duration of scrolling
 * @param {Object} $scrollableEl - scrollable element
 */
function scrollToPosition(scrollPos, duration, $scrollableEl) {
    duration = duration || 500;
    $scrollableEl = $scrollableEl || $('html, body');

    if (window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
    }

    $scrollableEl.animate({
        scrollTop: scrollPos || 0
    }, duration);
}

/**
 * Scrolls the window to the specific position/element
 * Example: scrollToAnchor('#product-details', { duration: 1000 });
 * @param {string} anchor - element selector which needs to be scrolled to
 * @param {Object} params - object of parameters - headerHeight, extraMargin, duration
 */
function scrollToAnchor(anchor, params) {
    if (anchor.length === 0 || typeof anchor !== 'string') return;

    var $anchor = $(anchor);

    if ($anchor.length === 0) return;

    params = params || {};

    const headerElemHeight = params.headerElemHeight || 0;
    const extraMargin = params.extraMargin || 0;
    const duration = params.duration || 500;
    const scrollPos = parseInt(($anchor.offset().top - headerElemHeight - extraMargin), 10);

    scrollToPosition(scrollPos, duration);
}

function subscribeMutationObserver(elements, callback) {
    // config object
    const config = { childList: true, characterData: true, subtree: true, attributes: true, };

    // set a MutationObserver for each element
    $(elements).each(function(index, el) {
        var target = $(el)[0];

        // set mutation observer
        var observer = new MutationObserver(function(mutationsList,observer) {
            callback();
        });

        // observer should disconnect after fulfilling its purpose
        // create event listener
        $(el).on('disconnectObserver', function() {
            observer.disconnect();
        });

        // set the observer on the target
        observer.observe(target, config);
    });
}

export {
    processInclude,
    appendToUrl,
    parseQueryString,
    getScreenSize,
    scrollToAnchor,
    scrollToPosition,
    mediaBreakpoints,
    breakpoints,
    elementInViewport,
    subscribeMutationObserver
};
