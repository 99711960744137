var Player = require('@vimeo/player').default;

var selectors = {
    videoPlayer: '.js-vimeo-player',
    videoBanner: '.js-video-banner',
    playPauseButton: '.js-play-button',
    muteUnmuteButton: '.js-mute-button'
};

var classes = {
    isPlaying: 'is-playing',
    isPaused: 'is-paused',
    isMuted: 'is-muted',
    isUnmuted: 'is-unmuted',
    displayNone: 'display-none'
};

var allVideoPlayers = [];
var defaultVideoOptions = {
    autopause: false,
    transparent: true
};

/**
 * create players for each player
 */
function createPlayers() {
    const { videoPlayer } = selectors;
    var players = $(videoPlayer);

    players.each(function(index, el) {
        if ($(el).is(':visible')) {
            var options = getVideoOptions(el);
            options = { ...defaultVideoOptions, ...options };

            allVideoPlayers[options.id] = createVideoPlayer(el, options);
        }
    });
}

/**
 * Creates vimeo video player
 * @param {Object} el - element to create vimeo video player
 * @param {Object} options - player options
 * @returns {Object} - video player
 */
function createVideoPlayer(el, options) {
    var player = new Player($(el), options);

    return player;
}

/**
 * @param {Object} el - element to create vimeo video player
 * @param {string} attribute - data attribute
 * @returns {Object} - player options
 */
function getVideoOptions(el) {
    var options = $(el).data('player-options');

    return options;
}

/**
 * @param {Object} el - element to create vimeo video player
 * @returns {string} - player id
 */
function getVideoId(el) {
    var options = $(el).data('player-options');

    return options.id;
}

/**
 * Play video
 * @param {string} id - video id
 */
function playVideo(id) {
    var player = allVideoPlayers[id];

    if (typeof player !== 'undefined') {
        player.getPaused().then(function(paused) {
            if (paused) {
                player.play().then(function() {
                    // The video was played
                }).catch(function(error) {
                    console.debug('Unable to play video');
                    console.debug(error);
                });
            }
        }).catch(function(error) {
            console.debug('Unable to get the paused state of the player.');
            console.debug(error);
        });
    }
}

/**
 * Pause video
 * @param {string} id - video id
 */
function pauseVideo(id) {
    var player = allVideoPlayers[id];

    if (typeof player !== 'undefined') {
        player.getPaused().then(function(paused) {
            if (!paused) {
                player.pause().then(function() {
                    // The video was paused
                }).catch(function(error) {
                    console.debug('Unable to pause video');
                    console.debug(error);
                });
            }
        }).catch(function(error) {
            console.debug('Unable to get the paused state of the player.');
            console.debug(error);
        });
    }
}

/**
 * Mute video
 * @param {string} id - video id
 */
function muteVideo(id) {
    var player = allVideoPlayers[id];

    player.getMuted().then(function(muted) {
        if (!muted) {
            player.setMuted(true).then(function() {
                // this video was muted
            }).catch(function(error) {
                // an error occurred
                console.debug(error);
            });
        }
    }).catch(function(error) {
        console.debug('Unable to get the muted state of the player.');
        console.debug(error);
    });
}

/**
 * Unmute video
 * @param {string} id - video id
 */
function unmuteVideo(id) {
    var player = allVideoPlayers[id];

    player.getMuted().then(function(muted) {
        if (muted) {
            player.setMuted(false).then(function() {
                // muted was turned off
            }).catch(function(error) {
                // an error occurred
                console.debug(error);
            });
        }
    }).catch(function(error) {
        console.debug('Unable to get the muted state of the player.');
        console.debug(error);
    });
}

/**
 * Handles play state of the video
 */
function handleVideoPlayPause() {
    const { isPaused, isPlaying } = classes;

    var $this = $(this);
    var target = $this.data('target');
    var $targetEl = $(target);
    var id = getVideoId(target);

    if (typeof allVideoPlayers[id] !== 'undefined') {
        if (!$this.hasClass(isPlaying)) {
            // in case the video had been intentionally paused, remove flag
            $this.removeClass(isPaused);
            $this.addClass(isPlaying);
            $targetEl.removeClass(isPaused);
            $targetEl.addClass(isPlaying);
            // play video
            playVideo(id);
        } else {
            // save that the video was paused
            $this.addClass(isPaused);
            $this.removeClass(isPlaying);
            $targetEl.removeClass(isPlaying);
            $targetEl.addClass(isPaused);
            pauseVideo(id);
        }
    }
}

/**
 * Handles mute state of the video
 */
function handleVideoMuteUnmute() {
    const { isMuted, isUnmuted } = classes;
    var $this = $(this);
    var target = $this.data('target');
    var id = getVideoId(target);

    if (typeof allVideoPlayers[id] !== 'undefined') {
        if (!$this.hasClass(isMuted)) {
            // in case the video had been intentionally muted, remove flag
            $this.removeClass(isUnmuted);
            $this.addClass(isMuted);
            muteVideo(id);
        } else {
            // save that the video was muted
            $this.addClass(isUnmuted);
            $this.removeClass(isMuted);
            unmuteVideo(id);
        }
    }
}

/**
 * Handles control's events
 */
function handleControlEvents() {
    const { playPauseButton, muteUnmuteButton } = selectors;

    $(document).on('click', playPauseButton, handleVideoPlayPause);
    $(document).on('click', muteUnmuteButton, handleVideoMuteUnmute);

    $(document).on('modal:afterOpen', function() {
        createPlayers();
    });

    $(document).on('modal:beforeClose', function(e) {
        const targetId = $(e.target).data('target');
        const videoId = getVideoId(targetId);

        pauseVideo(videoId);
    });
}

module.exports = {
    createPlayers,
    handleControlEvents
};
